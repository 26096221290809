import React, { useState } from 'react'
import PropTypes from 'prop-types';
import {graphql} from 'gatsby'
import {Link} from 'gatsby'
import Img from "gatsby-image"
import {format, distanceInWords, differenceInDays} from 'date-fns'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../../lib/helpers'
import Container from '../../components/container'
import GraphQLErrorList from '../../components/graphql-error-list'
import SEO from '../../components/seo'
import Layout from '../../containers/layout'
import styled from 'styled-components'
import { ContainerFullWidth, ContainerMain, Backdrop, ContainerTwoCol, ContainerContent } from '../../containers'
import Hero from '../../components/hero'
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import NumberFormat from 'react-number-format';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, BarChart, Bar
} from 'recharts';
import { MdHelpOutline, MdHelp } from "react-icons/md";
import ReactTooltip from 'react-tooltip';
import compoundInterestTable from '../../assets/compound_interest_table.png'
import compoundInterestGraph from '../../assets/compound_interest_graph.png'
import Sidebar from '../../components/Sidebar'
import { GraphDesktop, GraphMobile, AxisLabel } from '../../components/GraphComponents'
import { ThemedAccordion, FinancesTableWrapper, FinancesTableGrid, FinancesTableRow } from '../../components/CustomAccordion'
import ToggleButton from '../../components/ToggleButton'
import { InputGroup, InputSectionTitle, SectionLabel, HorizontalLine, FlexInput, InputPanel, InputLabel, InputItem } from '../../components/inputs'
import {Spacer12px } from '../../components/spacers'

const H2 = styled.h2`
  font-weight: 300;
`

const Image = styled.img`
  width: 100%;
`

const CalculatorGrid = styled(ContainerMain)`
  box-sizing: border-box;
  border: 1px solid ${ props => props.theme.theme.border.secondary};
  background-color: ${props => props.theme.theme.bg.primary};
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.05);
  border-radius: 8px;
  display: grid;
  grid-template-columns: minmax(350px, 1fr) 3fr;
  grid-gap: 48px;
  align-items: start;
  margin-bottom: 48px;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    padding: 24px 24px 0 24px;
    border: none;
    border-radius: 0px;
    margin: 0;
  }

  p, ul {
    font-size: 0.9rem;
    font-family: 'Inter', sans-serif;
  }
`

const ThemedTextarea = styled(TextField)`
  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.primary};
  }

  &.MuiFormControl-root {
    margin: 0 0 0px 0;
    background-color: ${props => props.theme.theme.bg.primary};
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.formBorder.primary};
  }
  
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiInputBase-input {
    color: ${props => props.theme.theme.text.primary};
  }
`

const ThemedTextareaPrimary = styled(ThemedTextarea)`
  &.MuiFormControl-root {
    margin: 24px 0;
  }

  &:hover{
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.theme.colors.green};
    }
  }

  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.secondary};
    font-weight: 500;
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }
`

const ThemedSlider = styled(Slider)`
  &.MuiSlider-root {
    color: ${props => props.theme.theme.colors.green};
  }

  .MuiSlider-markLabel, .MuiSlider-markLabelActive {
    color: ${props => props.theme.theme.text.primary};
  }
`

const Label = styled.h3`
    margin: auto 0px auto 0;
  font-size: 0.9rem;
  font-weight: 400;
  display: flex;

  svg {
    margin: 0 0 0 8px;
    height: 1.4rem;
  }
`


const CardText300 = styled.h3`
  margin: 0;
  font-size: 0.9rem;
`

const CardText400 = styled.h4`
  margin: 0;
  font-size: 0.9rem;
  font-weight: 400;
`

const TooltipPanel = styled.div`
  background-color: ${props => props.theme.theme.bg.secondary};
  box-shadow: 0 1px 2px 2px rgba(0,0,0,0.1);
  padding: 10px 10px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
`

const SliderGrid = styled.div`
  box-sizing: border-box;
  border: 0px solid ${ props => props.theme.theme.border.secondary};
  border-radius: 4px;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 16px;
  align-items: start;
`

const AllocationGroup = styled.div`
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 12px 12px 12px;
  margin: 0 0 24px 0;
  width: 100%;

  h3 {
    text-transform: uppercase;
    font-weight: 500;
  }
`;

const AllocationGroupGray = styled(AllocationGroup)`
  border: 1px solid ${(props) => props.theme.theme.border.tertiary};
  color: ${(props) => props.theme.theme.text.primary};

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.text.primary};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.border.tertiary};
  }

  .MuiRadio-colorSecondary.Mui-checked{
    color: ${props => props.theme.theme.colors.green};
  }
`;

const AllocationGroupGreen = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentGreen};
  border: 1px solid ${(props) => props.theme.theme.colors.green};
  /* color: ${(props) => props.theme.theme.colors.green}; */
  /* margin: 8px 8px 0 8px; */

  .result-value {
    font-weight: 500;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.green};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.colors.green};
  }
`;

const CustomTooltip = (props) => {
  const {active} = props

  if(active){
    const {payload, label } = props
    console.log(payload)
    console.log(label)

    return(
      <TooltipPanel>
        {/* <CardText300>{label}</CardText300> */}
        <CardText400>
          <strong>Year: </strong>
          {payload[0].payload.year}</CardText400>
        <CardText400>
          <strong>Future Value: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.localFutureValue.toFixed(0)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>
      </TooltipPanel>
    )
  }
  return(null)

}

const CustomTooltipHSA= (props) => {
  const {active} = props

  if(active){
    const {payload, label } = props
    console.log(payload)
    console.log(label)

    return(
      <TooltipPanel>
        {/* <CardText300>{label}</CardText300> */}
        <CardText400>
          <strong>Year: </strong>
          {payload[0].payload.year}
        </CardText400>
        <CardText400>
          <strong>Cumulative net deposits: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.totalDeposits.toFixed(0)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>
        <CardText400>
          <strong>Cumulative asset growth: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.totalAssetGrowth.toFixed(0)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>
        <CardText400>
          <strong>Total investment value: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.totalValue.toFixed(0)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>
      </TooltipPanel>
    )
  }
  return(null)

}

function NumberFormatCurrency(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCurrency.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function NumberFormatPercentage(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      suffix="%"
    />
  );
}

NumberFormatPercentage.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function NumberFormatInteger(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        isNumericString
      />
    );
  }
  
  NumberFormatInteger.propTypes = {
    inputRef: PropTypes.func.isRequired,
    // name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const AlcoholSavingsCalculator = () => {

  const [ investmentRate, setInvestmentRate ] = useState(7)
  const [ timeLength, setTimeLength ] = useState(10)
//   const [ principal, setPrincipal ] = useState(1000)
//   const [ payment, setPayment ] = useState(4150)
  const [ annualCompoundingPeriods, setAnnualCompoundingPeriods ] = useState(1)
  const [ annualPaymentPeriods, setAnnualPaymentPeriods ] = useState(1)
  
  const [ drinkingDaysPerWeekAtHome, setDrinkingDaysPerWeekAtHome ] = useState(1)
  const [ drinksPerDayAtHome, setDrinksPerDayAtHome ] = useState(3)
  const [ drinkingDaysPerWeekGoingOut, setDrinkingDaysPerWeekGoingOut ] = useState(1)
  const [ drinksPerDayGoingOut, setDrinksPerDayGoingOut ] = useState(4)
  const [ averageCostPerDrinkAtHome, setAverageCostPerDrinkAtHome ] = useState(2)
  const [ averageCostPerDrinkGoingOut, setAverageCostPerDrinkGoingOut ] = useState(7)
  const [ uberFrequency, setUberFrequency ] = useState(50)
  const [ averageUberCost, setAverageUberCost ] = useState(20)

  const annualCostOfDrinking = 52 * ( 
    (drinkingDaysPerWeekAtHome * drinksPerDayAtHome * averageCostPerDrinkAtHome) + 
    (drinkingDaysPerWeekGoingOut * drinksPerDayGoingOut * averageCostPerDrinkGoingOut) +
    (drinkingDaysPerWeekGoingOut * 2 * (uberFrequency/100) * averageUberCost)
    )

  const principal = annualCostOfDrinking
  const payment = annualCostOfDrinking

  const rate = (Math.pow( 1 + ((investmentRate/100)/annualCompoundingPeriods), annualCompoundingPeriods/annualPaymentPeriods )) - 1
  const nper = annualPaymentPeriods * timeLength
  const futureValue = principal * Math.pow(1+rate, nper) + (payment) * ( (Math.pow(1+rate, nper)-1)/ rate )

  const calcCompoundingData = () => {
    let compoundingData = []
    for(let i = 0; i <= timeLength; i++){
      console.log('test')
      const localNper = annualPaymentPeriods * i
      const localFutureValue = principal * Math.pow(1+rate, localNper) + (payment) * ( (Math.pow(1+rate, localNper)-1)/ rate )
      const totalDeposits = (payment) * i + principal

      compoundingData.push({
        year: i,
        totalAssetGrowth: localFutureValue - totalDeposits,
        totalDeposits: totalDeposits,
        totalValue: localFutureValue
      })
    }

    console.log(compoundingData)
    return compoundingData
  }

  const compoundingData = calcCompoundingData()

  return (
    <Layout>
      <SEO
        title={'Alcohol Savings Calculator'}
        description={'How much is your drinking really costing you?'}
      />
      <ReactTooltip />

      <CalculatorGrid>
        <form>
        <InputGroup>
            <InputSectionTitle>
              <SectionLabel>Drinking at home</SectionLabel>
              <HorizontalLine />
            </InputSectionTitle>

            <FlexInput style={{ width: "auto" }}>
              <InputPanel>
                <InputLabel>
                  Days per week
                </InputLabel>
                <InputItem style={{ width: "100px" }}>
                  <ThemedTextarea
                  type="number"
                  min={0}
                  id="outlined"
                  value={drinkingDaysPerWeekAtHome}
                  onChange={event => setDrinkingDaysPerWeekAtHome(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    inputProps: { 
                        min: 0 
                    }
                }}
                  />
                </InputItem>
              </InputPanel>
            </FlexInput>

            <FlexInput style={{ width: "auto" }}>
              <InputPanel>
                <InputLabel>
                  Drinks per day
                </InputLabel>
                <InputItem style={{ width: "100px" }}>
                  <ThemedTextarea
                  type="number"
                  min={0}
                  id="outlined"
                  value={drinksPerDayAtHome}
                  onChange={event => setDrinksPerDayAtHome(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    inputProps: { 
                        min: 0 
                    }
                }}
                  />
                </InputItem>
              </InputPanel>
            </FlexInput>

            <FlexInput style={{ width: "auto" }}>
              <InputPanel>
                <InputLabel>
                    Average cost per drink
                </InputLabel>
                <InputItem style={{ width: "100px" }}>
                  <ThemedTextarea
                  id="outlined"
                  value={averageCostPerDrinkAtHome}
                  onChange={event => setAverageCostPerDrinkAtHome(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCurrency,
                }}
                  />
                </InputItem>
              </InputPanel>
            </FlexInput>

        </InputGroup>

        <InputGroup>
            <InputSectionTitle>
              <SectionLabel>Drinking when going out</SectionLabel>
              <HorizontalLine />
            </InputSectionTitle>

            <FlexInput style={{ width: "auto" }}>
              <InputPanel>
                <InputLabel>
                  Days per week
                </InputLabel>
                <InputItem style={{ width: "100px" }}>
                  <ThemedTextarea
                  type="number"
                  min={0}
                  id="outlined"
                  value={drinkingDaysPerWeekGoingOut}
                  onChange={event => setDrinkingDaysPerWeekGoingOut(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    inputProps: { 
                        min: 0 
                    }
                }}
                  />
                </InputItem>
              </InputPanel>
            </FlexInput>

            <FlexInput style={{ width: "auto" }}>
              <InputPanel>
                <InputLabel>
                  Drinks per day
                </InputLabel>
                <InputItem style={{ width: "100px" }}>
                  <ThemedTextarea
                  type="number"
                  min={0}
                  id="outlined"
                  value={drinksPerDayGoingOut}
                  onChange={event => setDrinksPerDayGoingOut(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    inputProps: { 
                        min: 0 
                    }
                }}
                  />
                </InputItem>
              </InputPanel>
            </FlexInput>

            <FlexInput style={{ width: "auto" }}>
              <InputPanel>
                <InputLabel>
                    Average cost per drink
                </InputLabel>
                <InputItem style={{ width: "100px" }}>
                  <ThemedTextarea
                  id="outlined"
                  value={averageCostPerDrinkGoingOut}
                  onChange={event => setAverageCostPerDrinkGoingOut(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCurrency,
                }}
                  />
                </InputItem>
              </InputPanel>
            </FlexInput>

            <FlexInput style={{ width: "auto" }}>
              <InputPanel>
                <InputLabel>
                    How often do you pay for a ride?
                </InputLabel>
                <InputItem style={{ width: "100px" }}>
                  <ThemedTextarea
                  id="outlined"
                  value={uberFrequency}
                  onChange={event => setUberFrequency(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatPercentage,
                }}
                  />
                </InputItem>
              </InputPanel>
            </FlexInput>

            <FlexInput style={{ width: "auto" }}>
              <InputPanel>
                <InputLabel>
                    Average cost per ride
                </InputLabel>
                <InputItem style={{ width: "100px" }}>
                  <ThemedTextarea
                  id="outlined"
                  value={averageUberCost}
                  onChange={event => setAverageUberCost(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCurrency,
                }}
                  />
                </InputItem>
              </InputPanel>
            </FlexInput>

        </ InputGroup>

        <InputGroup>
            <InputSectionTitle>
              <SectionLabel>Opportunity cost</SectionLabel>
              <HorizontalLine />
            </InputSectionTitle>
        </ InputGroup>

        <Label>
          Investment rate of return
          {/* <MdHelp size="1.4rem"
              data-tip={`This is your etimated annual interest rate of return for your investment.`}
          /> */}
        </Label>
        <SliderGrid>

          <ThemedSlider
            value={investmentRate}
            aria-labelledby="discrete-slider-always"
            step={0.25}
            min={0}
            max={20}
            marks={[
              {
                value: 0,
                label: '0%'
              },
              {
                value: 7,
                label: '7%'
              },
              {
                value: 20,
                label: '20%'
              }
            ]}
            valueLabelDisplay="auto"
            onChange={ (event, value) => setInvestmentRate(value)}
          />

          <ThemedTextarea
            id="outlined-name"
            label="Rate"
            value={investmentRate}
            onChange={event => setInvestmentRate(event.target.value)}
            variant="outlined"
            InputProps={{
              inputComponent: NumberFormatPercentage,
            }}
            fullWidth
          />
        </SliderGrid>

        </form>
        <div>
            <AllocationGroupGreen>            
            You spend <span className="result-value">
                <NumberFormat
                displayType={"text"}
                value={(annualCostOfDrinking).toFixed(0)}
                thousandSeparator
                prefix="$"
                />
            </span> per year on drinking.

            <Spacer12px />

            That's <span className="result-value">
                <NumberFormat
                displayType={"text"}
                value={(annualCostOfDrinking/12).toFixed(0)}
                thousandSeparator
                prefix="$"
                />
            </span> per month.

            <Spacer12px />
            If you invested what you spend drinking in the stock market instead
            and earned a {investmentRate}% annual return, you would have <span className="result-value">
                <NumberFormat
                displayType={"text"}
                value={(futureValue).toFixed(0)}
                thousandSeparator
                prefix="$"
                />
            </span> after {timeLength} years.
            </AllocationGroupGreen>

        
        <GraphDesktop>
            <ResponsiveContainer width='100%' height={500} >
              <BarChart data={compoundingData}
                  margin={{top: 10, right: 0, left: 50, bottom: 30}}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="year" label={{value: "Time (years)", position: "insideBottom", offset: -10}} />
                <YAxis
                  tickFormatter={tick => {
                    return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                  }}
                />
                <Tooltip
                  content={<CustomTooltipHSA/>}
                />
                <Legend verticalAlign="top" height={36} />
                <Bar type='monotone' name="Cumulative net deposits" dataKey='totalDeposits' stackId="a" fill='#2196F3' />
                <Bar type='monotone' name="Cumulative asset growth" dataKey='totalAssetGrowth' stackId="a" fill='#3AC732' />
              </BarChart>
            </ResponsiveContainer>
          </GraphDesktop>

          <GraphMobile>
            <ResponsiveContainer width='100%' height={500} >
              <BarChart data={compoundingData}
                  margin={{top: 10, right: 0, left: 10, bottom: 30}}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="year" label={{value: "Time (years)", position: "insideBottom", offset: -10}} />
                <YAxis
                  tickFormatter={tick => {
                    return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                  }}
                  mirror
                />
                <Tooltip
                  content={<CustomTooltipHSA/>}
                />
                <Legend verticalAlign="top" height={36} />
                <Bar type='monotone' name="Cumulative net deposits" dataKey='totalDeposits' stackId="a" fill='#2196F3' />
                <Bar type='monotone' name="Cumulative asset growth" dataKey='totalAssetGrowth' stackId="a" fill='#3AC732' />
              </BarChart>
            </ResponsiveContainer>
          </GraphMobile>
        </div>

      </CalculatorGrid>

    </Layout>
  )
}

export default AlcoholSavingsCalculator

